<template>
  <div
    id="mobile-footer-accordion"
    class="col-md-12 col-lg-6 col-xl-5 offset-xl-1 mobile"
  >
    <accordion-footer
      :scroll="true"
      class="m-top-first"
      :name="column.columntitle.replace(' ', '-')"
      v-for="(column, j) in sectionData['leftcolumns']"
      :transitionTime="'0.7'"
      :key="j"
    >
      <template #title>
        <h2 class="h4 t-primary-light t-bold t-uppercase no-m">
          {{ column.columntitle }}
        </h2>
      </template>
      <template #content>
        <ul class="pb-1">
          <li v-for="(line, i) in column.links" :key="i" class="pb-2">
            <a :href="line.url" class="t-uppercase t-white">{{ line.display }}</a>
          </li>
        </ul>
      </template>
    </accordion-footer>
  </div>
</template>

<script>
import AccordionFooter from '../utils/accordion/accordionFooter.vue'
import * as yaml from 'js-yaml'
import * as fs from 'fs'
export default {
	name: 'FooterMobile',
	components: {
		AccordionFooter
	},
	data() {
		return {
			sectionData: {},
			currentOpened: null,
		}
	},
	mounted() {
		try {
			const fileContents = fs.readFileSync('_data/footer.yml', 'utf8')
			this.sectionData = yaml.safeLoad(fileContents)
		} catch (e) {
			console.log('error fething cms info', e)
		}
	},
	methods: {
		clickFunction(id) {
			if (this.currentOpened === id) this.currentOpened = null
			else {
				if (this.currentOpened != null) {
					var button = document.getElementById(
						'button-' +
							this.sectionData.leftcolumns[this.currentOpened].columntitle.replace(
								' ',
								'-'
							)
					)
					button && button.click()
				}
				this.currentOpened = id
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/sass/inc/variables';

#mobile-footer-accordion {
	margin-bottom: 20px;
}
#mobile-footer-accordion .accordion .card .card-content div ul {
	margin: 0;
}
#mobile-footer-accordion .accordion .card .card-content div ul li {
	margin-bottom: 12px;
}
#mobile-footer-accordion .accordion .card .card-content div ul li a {
	color: white;
}
#mobile-footer-accordion .accordion .card {
	background-color: #1d4045;
	border-top: none;
	border-bottom: solid 1px grey;
}
#mobile-footer-accordion .accordion .card .card-header .card-header-icon svg > * {
	fill: white !important;
}
#mobile-footer-accordion
	.accordion
	.card.card-active
	.card-header
	.card-header-icon
	svg
	> * {
	fill: #ffaa2b !important;
}
#mobile-footer-accordion {
	.panel.slide {
		max-height: 500px;
		transition: all 1s ease-in;
	}
	.panel {
		padding: 0;
		background-color: #1d4045;
		max-height: 0;
		transition: all 1s ease-in-out;
		-webkit-transition: all 1s ease-in-out;
		-moz-transition: all 1s ease-in-out;
		-ms-transition: all 1s ease-in-out;
		-o-transition: all 1s ease-in-out;
		overflow: hidden;
	}
	.accordion-row {
		color: white;
		cursor: pointer;
		padding: 25px 0px;
		border: none;
		text-align: left;
		outline: none;
		font-weight: bold;
	}
	.accordion {
		button {
			p {
				line-height: 100%;
			}
		}
		border-top: 1px solid lightgrey;
		&:last-child {
			border-bottom: 1px solid lightgrey;
		}
		&.line-secondary {
			border-top: 1px solid $brand-secondary-light-2;
			&:last-child {
				border-bottom: 1px solid $brand-secondary-light-2;
			}
		}
	}
}
</style>
